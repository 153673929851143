// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const bg: RequiredTranslationsTemplate = {
  meta: { localeName: 'Български' },
  home: {
    title: 'ИЗТЕГЛЯНЕ НА ПРОДУКТ\nКаска за планинско колоездене EXPL500 и ST500',
    description:
      'Благодарим Ви, че закупихте каска за планинско колоездене EXPL500 или ST500. Установихме дефект в една от пластмасовите части на каската. Съществува риск от счупване на тази част, вследствие на което каската няма да стои стабилно на главата. ',
    form: {
      title: 'Моля, въведете следната информация:',
      fields: {
        serialNumber: 'Партиден номер',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Собствено име',
        lastName: 'Фамилно име',
        email: 'Имейл',
        phone: 'Телефон',
        country: 'Държава',
        submit: 'Потвърди',
        choose: 'Избери',
        nc: 'ДРУГ (партидният номер на моята каска не фигурира в този списък)',
      },
      errors: {
        requiredField: 'Това поле е задължително',
        unknownSerialNumber: 'Неизвестен сериен номер',
        provide6Numbers: 'Моля, имайте предвид, че се изискват 6 цифри.',
      },
      confirmations: {
        good: {
          title: 'Каската Ви не е дефектна. ',
          p1: 'Потвърждаваме, че Вашата каска не е засегната от това изтегляне и можете да продължите да я използвате напълно безопасно.',
          p2: 'Молим да ни извините за причиненото неудобство.',
          p3: 'Поздрави,',
          p4: 'Екипът на DECATHLON',
        },
        defective: {
          title: 'Каската Ви е дефектна. ',
          p1: 'Молим Ви да не я използвате повече и да я върнете в най-близкия до Вас магазин DECATHLON, за да я замените или да Ви възстановим сумата, платена за нея.',
          p2: 'За повече информация можете да се свържете с нашия Център за връзка с клиенти.',
          p3: 'Потърсете най-близкия до Вас Сервиз на Decathlon:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'По-долу ще намерите талон за поправка, който да представите в Сервиза:',
          p5: 'За повече информация се свържете с нашия Център за обслужване на клиенти на Decathlon на телефон 02 902 10 70 от понеделник до неделя от 09:00 до 18:00 часа.',
        },
        'reset-form': 'Имам друга каска, която искам да проверя',
        exit: 'Изход',
        'find-shop': 'Магазини DECATHLON',
        resetForm: 'Имам друга каска, която искам да проверя',
        findShop: 'Магазини DECATHLON',
      },
    },
    findTheSerialNumber: {
      title: 'Къде мога да видя партидния номер на каската ми?',
      subTitle:
        'Партидният номер е посочен от вътрешната страна на каската под пяната в предната ѝ част.',
    },
    description2:
      'Само някои партидни номера, цветове и размери имат дефект. Приканваме Ви да изберете номера на партидата, съответстващ на посочения върху каската Ви, за да разберете дали Вашата каска е предмет на това изтегляне:',
  },
}

export default bg
