import React, { useEffect } from 'react'
// @ts-expect-error
import logo from '../../assets/images/logo.png'
import { Container, Element, Footer, Image, Section } from 'react-bulma-components'
import { Header } from './header'
import '../../styles/main.scss'
import { translate } from '../../utils/translations'
import { useTranslations } from '../../hooks/use-translations'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { current } = useTranslations()
  useEffect(() => {
    document.title = translate('home.title')
  }, [current])
  return (
    <>
      <Section className="pt-0 mt-0">
        <Container>
          <Header />
        </Container>
      </Section>
      {children}
      <Footer backgroundColor="white" textAlign={'center'}>
        <Image src={logo} style={{ maxHeight: 80, maxWidth: 200, margin: 'auto' }} />
        <Element>© 2024 - Fandi</Element>
      </Footer>
    </>
  )
}

export default Layout
