// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const bg: RequiredTranslationsTemplate = {
  meta: { localeName: 'Ελληνικά' },
  home: {
    title: 'ΑΝΑΚΛΗΣΗ ΠΡΟΙΟΝΤΟΣ κράνος ποδηλασίας βουνού EXPL500 και ST500',
    description:
      'Αγοράσατε ένα κράνος ποδηλάτου βουνού EXPL500 ή ST500 και σας ευχαριστούμε. Εντοπίσαμε ένα ελάττωμα σε ένα από τα πλαστικά μέρη που το αποτελούν. Αυτό μπορεί να σπάσει και σε αυτή την περίπτωση δεν μπορεί να εξασφαλίσει ότι το κράνος κρατιέται στο κεφάλι. \n',
    form: {
      title: 'Εισαγάγετε τις ακόλουθες πληροφορίες',
      fields: {
        serialNumber: 'Αριθμός παρτίδας',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Ονομα',
        lastName: 'Επώνυμο',
        email: 'e-mail',
        phone: 'Τηλέφωνο',
        country: 'Χώρα',
        submit: 'Επικύρωση',
        choose: 'Επιλέξτε',
        nc: 'ΑΛΛΟ (ο αριθμός παρτίδας μου δεν βρίσκεται σε αυτήν τη λίστα)',
      },
      errors: {
        requiredField: 'Αυτό το πεδίο είναι υποχρεωτικό',
        unknownSerialNumber: 'Неизвестен сериен номер',
        provide6Numbers: 'Моля, имайте предвид, че се изискват 6 цифри.',
      },
      confirmations: {
        good: {
          title: 'Το κράνος σας δεν επηρεάζεται.',
          p1: 'Επιβεβαιώνουμε ότι το κράνος σας δεν επηρεάζεται από αυτήν την ανάκληση, επομένως μπορείτε να συνεχίσετε να τα χρησιμοποιείτε με απόλυτη ασφάλεια.',
          p2: 'Ζητούμε συγγνώμη για την ταλαιπωρία που προκλήθηκε.',
          p3: 'Αθλητικά,',
          p4: 'Η ομάδα της Decathlon',
        },
        defective: {
          title: 'Το κράνος σας επηρεάζεται.',
          p1: 'Σας ζητάμε να μην το χρησιμοποιείτε πλέον και να επικοινωνήσετε με το κατάστημα Decathlon της επιλογής σας προκειμένου να το επιστρέψετε ή να το αντικαταστήσετε.',
          p2: 'Για περισσότερες πληροφορίες, σας συμβουλεύουμε να επικοινωνήσετε με το κέντρο εξυπηρέτησης πελατών, το οποίο μπορείτε να βρείτε στο email που λάβατε.',
          p3: 'Потърсете най-близкия до Вас Сервиз на Decathlon:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'По-долу ще намерите талон за поправка, който да представите в Сервиза:',
          p5: 'За повече информация се свържете с нашия Център за обслужване на клиенти на Decathlon на телефон 02 902 10 70 от понеделник до неделя от 09:00 до 18:00 часа.',
        },
        'reset-form': 'Έχω άλλο κράνος να ελέγξω',
        exit: 'Έξοδος',
        'find-shop': 'Εύρεση καταστήματος',
        resetForm: 'Έχω άλλο κράνος να ελέγξω',
        findShop: 'Εύρεση καταστήματος',
      },
    },
    findTheSerialNumber: {
      title: 'Πού μπορώ να βρω τον αριθμό παρτίδας μου;',
      description: 'Серийният номер е изписан върху вилката.',
      subTitle:
        'Ο αριθμός παρτίδας βρίσκεται μέσα στο κράνος κάτω από τον αφρό που βρίσκεται στο μπροστινό μέρος του κράνους.',
    },
    description2:
      'Επηρεάζονται μόνο ορισμένοι αριθμοί παρτίδων, ορισμένων χρωμάτων και μεγεθών. Σας προσκαλούμε να επιλέξετε τον αριθμό παρτίδας που αντιστοιχεί σε αυτόν που υποδεικνύεται στο κράνος σας για να μάθετε εάν το κράνος σας επηρεάζεται από αυτήν την εκστρατεία ανάκλησης:',
  },
}

export default bg
