import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { CheckStatusData } from '../../../backend/src/services/product-items/product-items.schema'
import app from '../api/feathers'
import { FeathersError } from '@feathersjs/errors'

// Query client
export const queryClient = new QueryClient()

//TODO: move to specific file
export const useSerialNumbersFamiliesQuery = (): UseQueryResult<string[]> =>
  useQuery({
    queryKey: ['product-items', 'serial-numbers-families'],
    queryFn: async () => {
      const { result } = await app.service('product-items').getSerialNumbersFamilies()
      return result
    },
  })

export const useCheckSerialNumberStatusMutation = (): UseMutationResult<
  CheckStatusData,
  FeathersError,
  CheckStatusData
> =>
  useMutation({
    mutationFn: async data => {
      console.log(data)
      return await app.service('product-items').checkStatus(data)
    },
  })

export const useBikeRecallStatus = (serialNumber: string) => {
  return useQuery({
    queryKey: ['product-items', { serialNumber }],
    queryFn: () => {
      const query = app.service('product-items').find({
        query: { 'serialNumber.complete': serialNumber },
      })
      return query
    },
  })
}
