// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const ro: RequiredTranslationsTemplate = {
  meta: { localeName: 'Romana' },
  home: {
    title: 'Retragerea de pe piață a produsului Cască ROCKRIDER MTB EXPL500 sau ST500',
    description:
      'În ciuda procedurilor riguroase de control al calității, am constatat că mai multe loturi de căști EXPL500 și ST500 nu îndeplinesc cerințele de siguranță. Una dintre piesele din plastic, aflată în partea din spate a căștii, care ține cureaua de fixare sub bărbie, se poate rupe. Dacă acest lucru se întâmplă, există riscul ca, în cazul unei căzături, casca să nu rămână fixată ferm pe cap la impact.',
    form: {
      title: 'Vă rugăm să introduceți următoarele informații',
      fields: {
        serialNumber: 'Număr lot',
        firstName: 'Prenume',
        lastName: 'Nume',
        email: 'adresa de e-mail',
        phone: 'număr telefon',
        country: 'țara',
        submit: 'Trimiteți',
        choose: 'Alege',
        nc: 'ALTELE (numărul meu de lot nu este pe această listă)',
      },
      errors: { requiredField: 'Acest câmp este obligatoriu' },
      confirmations: {
        good: {
          title: 'Casca ta nu este afectată.',
          p1: 'Confirmăm că produsul dvs. nu este afectat de această retragere, astfel încât să puteți continua să-l utilizați în siguranță.',
          p2: 'Ne cerem scuze pentru orice inconvenient cauzat.',
          p3: 'Cu sportivitate,',
          p4: 'Echipa Decathlon',
        },
        defective: {
          title: 'Casca ta este afectată.',
          p1: 'Vă rugăm să încetați imediat utilizarea acestui produs și să vă aduceți casca la cel mai apropiat magazin Decathlon, pentru a proceda la rambursarea sau înlocuirea produsului.',
          p2: 'Pentru mai multe informații, vă recomandăm să contactați centrul de asistență pentru clienți. Detaliile de contact pot fi găsite în e-mailul primit.',
          p3: 'Pentru a găsi cel mai apropiat Atelier Decathlon:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'Veți găsi mai jos „Notificarea de retur client” pentru a o prezenta Tehnicianului nostru când returnați bicicleta la atelier:',
          p5: 'Pentru mai multe informații, vă rugăm să ne contactați la numarul de telefon 021 408 5005 de Luni-Vineri 09h00 la 20h00 și Sâmbăta-Duminica 10h00 la 19h00',
        },
        resetForm: 'Mai am o cască de verificat.',
        exit: 'Ieșire',
        findShop: 'Găsiți magazinul meu',
      },
    },
    findTheSerialNumber: {
      title: 'Unde pot găsi numărul de lot?',
      subTitle: 'Numărul de lot este situat în interiorul căștii, sub căptușeala din față.',
    },
    description2:
      'Pentru a verifica dacă casca dvs. este afectată de acest defect, vă rugăm să accesați linkul de mai jos. Vi se va cere să selectați numărul lotului aferent căștii dvs. și veți fi îndrumat(ă) prin proces.',
  },
}

export default ro
