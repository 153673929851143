import React from 'react'
import { t } from 'react-i18nify'

export const translate = (key: string): string => {
  const translation = t(key, undefined, { returnNullOnError: true })
  if (translation !== null) return translation
  const enTranslation = t(key, undefined, { locale: 'en', returnNullOnError: true })
  if (enTranslation !== null) return enTranslation
  const frTranslation = t(key, undefined, { locale: 'fr' })
  return frTranslation
}

export const Translate: React.FC<{ value: string }> = ({ value }) => {
  const translation = translate(value)
  return <>{translation}</>
}
