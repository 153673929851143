// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const es: RequiredTranslationsTemplate = {
  meta: { localeName: 'Español' },
  home: {
    title: 'RETIRADA PRODUCTO\nCasco BTT EXPL500 y ST500',
    description:
      'Has comprado un casco para bicicleta de montaña EXPL500 o ST500 y te damos las gracias por ello.\nHemos detectado un defecto en una de las piezas de plástico que lo componen. Esto impide que el casco\ncumpla plenamente su función de protección. ',
    form: {
      title: 'Por favor, indica la información siguiente',
      fields: {
        firstName: 'Nombre',
        lastName: 'Apellidos',
        email: 'Dirección de correo electrónico',
        phone: 'Teléfono',
        country: 'País',
        serialNumber: 'N.° de lote',
        choose: 'Seleccionar',
        nc: 'OTRO (mi número de lote no está en la lista)',
        submit: 'Validar',
      },
      errors: { requiredField: 'Este campo es obligatorio' },
      confirmations: {
        defective: {
          title: 'Tu casco está afectado',
          p1: 'Te rogamos que dejes de utilizarlo y que acudas a la tienda Decathlon de tu elección, con tu casco, para que procedamos a su reembolso o sustitución.\n',
          p2: 'Para más información, te aconsejamos que contactes con el centro de atención al cliente, cuyos datos se\r\nencuentran en el correo electrónico que has recibido',
        },
        good: {
          title: 'Tu casco no está afectado',
          p1: 'Te confirmamos que tu casco no está afectado por este aviso de producto, así que puedes seguir usándolo con total seguridad',
          p2: 'Lamentamos las molestias que te hayamos podido ocasionar',
          p3: 'Deportivamente',
          p4: 'Los equipos de Decathlon',
        },
        resetForm: 'Tengo otro casco para verificar',
        exit: 'Salir',
        findShop: 'Encontrar mi tienda',
      },
    },
    findTheSerialNumber: {
      title: '¿Dónde puedo encontrar mi número de lote?',
      subTitle:
        'El número de lote se encuentra en el interior del casco, bajo el acolchado de la parte delantera',
    },
    description2:
      'Solo están afectados algunos números de lote, así que te\r\nrogamos que indiques las últimas cifras de tu n.° de lote para saber si tu casco está afectado por esta\r\ncampaña de retirada:',
  },
}

export default es
