// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const pt: RequiredTranslationsTemplate = {
  meta: { localeName: 'Brazil' },
  home: {
    title: 'RECALL PRODUTO capacete MTB EXPL500 e ST500',
    description:
      'Você adquiriu um capacete de mountain bike EXPL500 ou ST500 e agradecemos. Identificamos um defeito em uma das peças plásticas que o compõem. Esta peça pode quebrar e neste caso não garantir que o capacete fique preso na sua cabeça. ',
    description2:
      'Apenas determinados números de lote, de determinadas cores e tamanhos, são afetados. Te convidamos a verificar o número do lote indicado no seu capacete para saber se o seu capacete é afetado por esta campanha de recall:',
    form: {
      title: 'Favor preencher as seguintes informações',
      fields: {
        firstName: 'Nome',
        lastName: 'Sobrenome',
        email: 'email',
        phone: 'Tel.',
        country: 'Pais',
        serialNumber: 'N° de Lote',
        choose: 'Escolher',
        nc: 'OUTRO (meu número de lote não está nesta lista)',
        submit: 'Para validar',
      },
      errors: { requiredField: 'Este campo é obrigatório' },
      confirmations: {
        defective: {
          title: 'Seu capacete foi afetado.',
          p1: 'Pedimos que não o utilize mais e que se dirija à loja Decathlon da sua preferência, munido do seu capacete, para o reembolso ou substituição do Capacete.',
          p2: 'Para mais informações, aconselhamos que contacte o seu centro de relacionamento com o cliente que se encontra no e-mail a saguir.',
        },
        good: {
          title: 'Seu capacete não é afetado.',
          p1: 'Confirmamos que o seu capacete não foi afetado por este recall, assim você poderá continuar a usá-lo com total segurança.',
          p2: 'Pedimos desculpas pelo transtorno causado.',
          p3: 'Desportivamente,',
          p4: 'Equipe Decathlon',
        },
        resetForm: 'Eu tenho outro capacete para verificar',
        exit: 'Sair',
        findShop: 'Encontre minha loja',
      },
    },
    findTheSerialNumber: {
      title: 'Onde se encontra meu número de lote ?',
      subTitle:
        'O número de lote sae encontra no "interior do capacete embaixo da espuma, cituada na parte da frente do capacete".',
    },
  },
}

export default pt
