// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const est: RequiredTranslationsTemplate = {
  meta: { localeName: 'Eesti' },
  home: {
    title: 'Toote tagasikutsumine ROCKRIDER kiiver MTB EXPL500 või ST500 ',
    description:
      'Täname, et ostsite maastikurattakiivri EXPL500 või ST500. Oleme tuvastanud ühes plastikkomponendis defekti, mis võib puruneda ja kahjustada kiivri võimet kindlalt peas püsida.',
    form: {
      title: 'Palun sisestage järgmine teave',
      fields: {
        serialNumber: 'Partii number',
        firstName: 'Eesnimi:',
        lastName: 'Perekonnanimi:',
        email: 'E-posti aadress:',
        phone: 'Telefoninumber:',
        country: 'Riik:',
        submit: 'Esita',
        choose: 'Vali',
        nc: 'MUU (minu partii numbrit selles loendis ei ole)',
      },
      errors: { requiredField: 'See väli on kohustuslik' },
      confirmations: {
        good: {
          title: 'Teie kiivrit see ei mõjuta.',
          p1: 'Kinnitame, et see tagasikutsumine teie kiivrit ei puuduta, seega saate oma toodet ohutult edasi kasutada.',
          p2: 'Vabandame võimalike ebamugavuste pärast.',
          p3: 'Parimate soovidega',
          p4: 'Decathloni meeskond',
        },
        defective: {
          title: 'Teie kiiver kuulub tagasikutsumise alla. ',
          p1: 'Palun lõpetage kohe kiivri kasutamine ning tooge see lähimasse Decathloni poodi, et saaksime teile raha tagastada või toote välja vahetada.',
          p2: 'Lisateabe saamiseks soovitame võtta ühendust klienditoe meeskonnaga. Kontaktandmed leiate saadud meilist.',
          p3: 'Pentru a găsi cel mai apropiat Atelier Decathlon:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'Veți găsi mai jos „Notificarea de retur client” pentru a o prezenta Tehnicianului nostru când returnați bicicleta la atelier:',
          p5: 'Pentru mai multe informații, vă rugăm să ne contactați la numarul de telefon 021 408 5005 de Luni-Vineri 09h00 la 20h00 și Sâmbăta-Duminica 10h00 la 19h00',
        },
        resetForm: 'Mul on veel üks kiiver kontrollida.',
        exit: 'Välju',
        findShop: 'Leia minu pood',
      },
    },
    findTheSerialNumber: {
      title: 'Kust ma leian oma partii numbri?',
      subTitle: 'Partii number asub kiivri sees, esiosa polstri all.',
    },
    description2:
      'See mõjutab ainult teatud partii numbreid, värve ja suurusi. Kutsume teid kontrollima oma kiivri partiinumbrit, et näha, kas see tagasikutsumine mõjutab teie toodet.',
  },
}

export default est
