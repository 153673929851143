function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
export default class Base extends React.Component {
  static rerenderAll() {
    Base.instances.forEach(instance => instance.forceUpdate());
  }

  componentDidMount() {
    Base.instances.push(this);
  }

  componentWillUnmount() {
    Base.instances.splice(Base.instances.indexOf(this), 1);
  }

}

_defineProperty(Base, "instances", []);

export const forceComponentsUpdate = () => {
  Base.rerenderAll();
};