// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const pl: RequiredTranslationsTemplate = {
  meta: { localeName: 'Polski' },
  home: {
    title: 'WYCOFANIE PRODUKTU kask MTB EXPL500 i ST500',
    description:
      'Zakupiłeś kask MTB EXPL500 lub ST500, za co dziękujemy. Zidentyfikowaliśmy wadę jednej z jego plastikowych części. Może się ona złamać i w takim przypadku nie będzie mogła zapewnić utrzymania kasku na głowie.',
    form: {
      title: 'Prosimy wprowadzić następujące informacje',
      fields: {
        firstName: 'Imię',
        lastName: 'Nazwisko',
        email: 'email',
        phone: 'Tel.',
        country: 'Kraj',
        serialNumber: 'Numer partii',
        choose: 'Wybierz',
        nc: 'INNY (Twojego numeru partii nie ma na tej liście)',
        submit: 'Potwierdź\n',
      },
      errors: { requiredField: 'To pole jest wymagane' },
      confirmations: {
        defective: {
          title: 'Wycofanie dotyczy Twojego kasku.',
          p1: 'Prosimy o zaprzestanie korzystania z kasku i zaniesienie go do wybranego sklepu Decathlon, w celu dokonania zwrotu lub wymiany.',
          p2: 'Aby uzyskać dodatkowe informacje, zapraszamy do kontaktu z centrum obsługi klienta, którego adres znajdziesz we wcześniej otrzymanym mailu.',
        },
        good: {
          title: 'Twój kask nie jest objęty tą procedurą',
          p1: 'Informujemy, że procedura wycofania produktu nie dotyczy Twojego kasku, możesz więc nadal bezpiecznie z niego korzystać.',
          p2: 'Przepraszamy za zaistniałe niedogodności.',
          p3: 'Ze sportowym pozdrowieniem,\n',
          p4: 'Zespół Decathlon\n',
        },
        resetForm: 'Chcę sprawdzić kolejny kask',
        exit: 'Wyjdź',
        findShop: 'Znajdź sklep',
      },
    },
    findTheSerialNumber: {
      title: 'Gdzie znajdziesz numer partii?',
      subTitle: 'Numer partii znajduję się wewnątrz kasku, w przedniej części pod pianką.',
    },
    description2:
      'Dotyczy to tylko niektórych numerów partii oraz określonych kolorów i rozmiarów.\nZachęcamy do wybrania numeru partii odpowiadającego numerowi partii wskazanemu na Twoim kasku, aby dowiedzieć się, czy jest objęty wycofaniem:',
  },
}

export default pl
