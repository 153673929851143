import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from '../../hooks/use-translations'

export const BrowserLanguageDetector: React.FC = () => {
  const { all } = useTranslations()
  const navigate = useNavigate()
  const browserLanguage = navigator.language
  const supportedLanguages = Object.keys(all)
  const languageMatch = supportedLanguages.find(
    supportedLanguage => supportedLanguage === browserLanguage,
  )
  useEffect(() => {
    if (languageMatch) navigate(`/${languageMatch}`)
    else navigate('/en') // TEMP for test => real default is 'en'
  }, [])
  return <></>
}
