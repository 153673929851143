import React from 'react'
import { useRoutes as routerUseRoutes } from 'react-router-dom'
import { BrowserLanguageDetector } from '../components/localization/browser-language-detector'
import { RecallStatusPage } from '../components/product-items/recall-status'
import Homepage from '../pages/home'

const useRoute = (): React.ReactElement | null => {
  return routerUseRoutes([
    //{ path: '/:lang/recall-status/:serialNumber', element: <RecallStatusPage /> },
    { path: '/:lang', element: <Homepage /> },
    { path: '/', element: <BrowserLanguageDetector /> },
  ])
}

export default useRoute
