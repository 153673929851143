import bg from './bg'
import cs from './cs'
import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import hr from './hr'
import it from './it'
import nl from './nl'
import pt from './pt'
import el from './el'
import sk from './sk'
import sl from './sl'
import hu from './hu'
import ptBz from './pt-bz'
import deChe from './de-che'
import itChe from './it-che'
import pl from './pl'
import ro from './ro'
import ltu from './ltu'
import lva from './lva'
import est from './est'

export default {
  fr,
  en,
  es,
  it,
  ptBz,
  bg,
  hr,
  cs,
  de,
  el,
  hu,
  nl,
  pl,
  pt,
  ro,
  sk,
  sl,
  itChe,
  deChe,
  ltu,
  lva,
  est,
}

export interface RequiredTranslationsTemplate {
  meta: {
    localeName: string
  }
  home: {
    title: string
    description: string
    description2: string
    form: {
      title: string
      fields: {
        serialNumber: string
        //serialNumberFamily: string
        firstName: string
        lastName: string
        email: string
        phone: string
        country: string
        submit: string
        choose: string
        nc: string
      }
      errors: {
        requiredField: string
        //unknownSerialNumber: string
        //provide6Numbers: string
      }
      confirmations: {
        good: {
          title: string
          p1: string
          p2: string
          p3: string
          p4: string
        }
        defective: {
          title: string
          p1: string
          p2: string
          //p3: string
          //decathlonLocatorLink: string
          //p4: string
          //p5: string
        }
        resetForm: string
        exit: string
        findShop: string
      }
    }
    findTheSerialNumber: {
      title: string
      subTitle: string
    }
  }
}

export type PartialTranslationsTemplate = DeepPartial<RequiredTranslationsTemplate>

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T
