import React from 'react'
import useRoute from '../hooks/use-route'

const App: React.FunctionComponent = () => {
  // Get route by pathname
  const route = useRoute()

  return <>{route}</>
}

export default App
