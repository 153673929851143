import React from 'react'
import {
  Button,
  Columns,
  Container,
  Element,
  Heading,
  Image,
  Section,
} from 'react-bulma-components'
import Layout from '../components/layout/layout'
import CheckStatusForm from '../components/product-items/check-status-form'
import { useCheckSerialNumberStatusMutation } from '../queries'
// @ts-expect-error
import rockRiderLogo from '../assets/images/decathlon-rockrider-logo.png'
// @ts-expect-error
import helmetBlack from '../assets/images/helmet-black.png'
// @ts-expect-error
import helmetBlue from '../assets/images/helmet-blue.png'
// @ts-expect-error
import helmetSurfGreen from '../assets/images/helmet-surf-green.png'
// @ts-expect-error
import helmetGreen from '../assets/images/helmet-forest-green.png'
import { Translate } from '../utils/translations'
import { BiCheckCircle } from 'react-icons/bi'
import { IoWarningOutline } from 'react-icons/io5'

const HomePage: React.FC = () => {
  const mutation = useCheckSerialNumberStatusMutation()

  return (
    <Layout>
      <Image src={rockRiderLogo} style={{ maxWidth: 300, margin: 'auto' }} />
      <Section pb={2}>
        <Container>
          <Heading size={2} textAlign="center" mb={6}>
            <Translate value="home.title" />
          </Heading>
          <Columns className="is-mobile" mt={6}>
            <Columns.Column>
              <Image src={helmetBlack} style={{ maxWidth: 180, margin: 'auto' }} />
            </Columns.Column>
            <Columns.Column>
              <Image src={helmetSurfGreen} style={{ maxWidth: 180, margin: 'auto' }} />
            </Columns.Column>
            <Columns.Column>
              <Image src={helmetBlue} style={{ maxWidth: 180, margin: 'auto' }} />
            </Columns.Column>
            <Columns.Column>
              <Image src={helmetGreen} style={{ maxWidth: 180, margin: 'auto' }} />
            </Columns.Column>
          </Columns>
          {!mutation.isSuccess && (
            <Columns centered>
              <Columns.Column size={10}>
                <Element renderAs="p" mt={5} textSize={5} textAlign="center">
                  <Translate value="home.description" />
                  <br />
                  <Translate value="home.description2" />
                </Element>
              </Columns.Column>
            </Columns>
          )}
        </Container>
      </Section>
      <Section mb={6}>
        <Container>
          {mutation.isSuccess ? (
            <>
              <Element textAlign={'center'} style={{ maxWidth: 800, margin: 'auto' }}>
                {mutation.data.batchNumber === 'nc' ? (
                  <>
                    <Element style={{ fontSize: 60 }} textColor="success">
                      <BiCheckCircle />
                    </Element>
                    <Heading size={3}>
                      <Translate value="home.form.confirmations.good.title"></Translate>
                    </Heading>
                    <Element renderAs="p" mb={3}>
                      <Translate value="home.form.confirmations.good.p1"></Translate>
                    </Element>
                    <Element renderAs="p" mb={3}>
                      <Translate value="home.form.confirmations.good.p2"></Translate>
                    </Element>
                    <Element renderAs="p" mb={3}>
                      <Translate value="home.form.confirmations.good.p3"></Translate>
                    </Element>
                    <Element renderAs="p" mb={3}>
                      <Translate value="home.form.confirmations.good.p4"></Translate>
                    </Element>
                    <Element mt={6}>
                      <Button color="info" onClick={() => mutation.reset()} mr={2}>
                        <Translate value="home.form.confirmations.resetForm"></Translate>
                      </Button>
                      <Button renderAs="a" href="https://www.decathlon.fr/">
                        <Translate value="home.form.confirmations.exit"></Translate>
                      </Button>
                    </Element>
                  </>
                ) : (
                  <>
                    <Element style={{ fontSize: 60 }} textColor="danger">
                      <IoWarningOutline />
                    </Element>
                    <Heading size={3}>
                      <Translate value="home.form.confirmations.defective.title"></Translate>
                    </Heading>
                    {mutation.data.batchNumber && (
                      <Heading subtitle size={5}>
                        {mutation.data.batchNumber}
                      </Heading>
                    )}
                    <Element renderAs="p" mb={3}>
                      <Translate value="home.form.confirmations.defective.p1"></Translate>
                    </Element>
                    <Element renderAs="p" mb={3}>
                      <Translate value="home.form.confirmations.defective.p2"></Translate>
                    </Element>
                    <Element mt={6}>
                      <Button
                        color="info"
                        mr={2}
                        renderAs="a"
                        href="https://www.decathlon.fr/store-locator"
                      >
                        <Translate value="home.form.confirmations.findShop"></Translate>
                      </Button>
                      <Button color="info" onClick={() => mutation.reset()} mr={2}>
                        <Translate value="home.form.confirmations.resetForm"></Translate>
                      </Button>
                      <Button renderAs="a" href="https://www.decathlon.fr/">
                        <Translate value="home.form.confirmations.exit"></Translate>
                      </Button>
                    </Element>
                  </>
                )}
              </Element>
            </>
          ) : (
            <Element>
              <Heading size={3} mb={6} pb={3} textAlign="center">
                <Translate value="home.form.title" />
              </Heading>
              <CheckStatusForm onSubmit={mutation.mutate} />
            </Element>
          )}
          {/* {mutation.error?.code === 404 && <Element>Unknown doc</Element>}
          {mutation.data?.status === 'good' && <Element>Good item</Element>}
          {mutation.data?.status === 'defective' && <Element>Bad item</Element>} */}
        </Container>
      </Section>
    </Layout>
  )
}

export default HomePage
