// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const ltu: RequiredTranslationsTemplate = {
  meta: { localeName: 'Lietuvių' },
  home: {
    title: 'Gaminio išėmimas iš apyvartos – ROCKRIDER šalmas MTB EXPL500 arba ST500',
    description:
      'Ačiū, kad įsigijote ROCKRIDER MTB EXPL500 šalmą, dar žinomą kaip MTB ST500. nustatėme, kad kelios EXPL500 ir ST500 šalmų partijos neatitinka reikalavimų. Galinėje šalmo pusėje gali lūžti plastikinė dalis, laikanti smakro dirželį. Jei taip nutiktų, krentant šalmas nesilaikytų.',
    form: {
      title: 'Prašau įveskite informaciją',
      fields: {
        serialNumber: 'Partijos numeris',
        firstName: 'Vardas',
        lastName: 'Pavardė',
        email: 'El. paštas:',
        phone: 'Telefono numeris',
        country: 'Šalis:',
        submit: 'Patvirtinti',
        choose: 'Pasirinkti',
        nc: '',
      },
      errors: { requiredField: 'Šis laukelis yra privalomas' },
      confirmations: {
        good: {
          title: 'Jūsų šalmas nėra pažeistas.',
          p1: 'Patvirtiname, kad jūsų šalmas nėra pažeistas, todėl galite toliau saugiai naudotis produktu.',
          p2: 'Atsiprašome už sukeltus nepatogumus',
          p3: 'Linkėjimai',
          p4: 'Decathlon komanda',
        },
        defective: {
          title: 'Jūsų šalmas yra pažeistas.',
          p1: 'Prašome nedelsiant nustoti naudoti šį gaminį ir atnešti šalmą į artimiausią Decathlon parduotuvę, kad galėtumėte grąžinti pinigus arba pakeisti gaminį.',
          p2: 'Norėdami gauti daugiau informacijos, rekomenduojame susisiekti su klientų aptarnavimo centru. Kontaktinius duomenis rasite gautame el. laiške.',
          p3: 'Pentru a găsi cel mai apropiat Atelier Decathlon:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'Veți găsi mai jos „Notificarea de retur client” pentru a o prezenta Tehnicianului nostru când returnați bicicleta la atelier:',
          p5: 'Pentru mai multe informații, vă rugăm să ne contactați la numarul de telefon 021 408 5005 de Luni-Vineri 09h00 la 20h00 și Sâmbăta-Duminica 10h00 la 19h00',
        },
        resetForm: 'Tikrinti kitą šalmą',
        exit: 'Išeiti',
        findShop: 'Rasti mano parduotuvę',
      },
    },
    findTheSerialNumber: { title: 'Kur galiu rasti savo partijos numerį?', subTitle: '' },
    description2:
      'Norėdami patikrinti, ar jūsų šalmas turi šį defektą, spustelėkite toliau pateiktą nuorodą. Jūsų bus paprašyta pasirinkti šalmo partijos numerį ir bus paaiškinta, kaip elgtis.',
  },
}

export default ltu
