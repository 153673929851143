import React, { useState } from 'react'
import { Navbar, Image } from 'react-bulma-components'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslations } from '../../hooks/use-translations'

// @ts-expect-error
import logo from '../../assets/images/logo.png'
import { Translate } from '../../utils/translations'
import { sortBy } from 'lodash'

export const Header: React.FC = () => {
  const location = useLocation()
  const locationWithoutLanguage = location.pathname.split('/').slice(2).join('/') + location.search
  const [showMenu, setShowMenu] = useState(false)
  const { current, all } = useTranslations()

  const languages = sortBy(
    Object.keys(all).map(locale => ({ name: all[locale], locale })),
    'name',
  )

  return (
    <Navbar>
      <Navbar.Brand>
        <Navbar.Item renderAs={NavLink} to={`/${current}`}>
          <Image src={logo} style={{ maxHeight: 80 }} />
        </Navbar.Item>
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Item hoverable onClick={() => setShowMenu(!showMenu)}>
          <Navbar.Link>
            <Translate value="meta.localeName" />
          </Navbar.Link>
          <Navbar.Dropdown right>
            {languages.map(l => {
              return (
                <Navbar.Item
                  renderAs={NavLink}
                  to={`/${l.locale}/${locationWithoutLanguage}`}
                  key={l.locale}
                >
                  {l.name}
                </Navbar.Item>
              )
            })}
          </Navbar.Dropdown>
        </Navbar.Item>
      </Navbar.Menu>
    </Navbar>
  )
}
