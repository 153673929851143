import { RequiredTranslationsTemplate } from '.'

const fr: RequiredTranslationsTemplate = {
  home: {
    title: 'RAPPEL PRODUIT casque de VTT EXPL500 et ST500',
    description:
      "Vous avez acheté un casque de VTT EXPL500 ou ST500 et nous vous en remercions. Nous avons identifié un défaut sur une des pièces plastique qui le compose. Celle ci peut se casser et dans ce cas ne permet d'assurer le maintien du casque sur la tête.",
    form: {
      title: 'Merci de saisir les informations suivantes',
      fields: {
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'email',
        phone: 'Tél.',
        country: 'Pays',
        serialNumber: 'N° de lot',
        choose: 'Choisir',
        nc: "AUTRE (mon numéro de lot n'est pas dans cette liste)",
        submit: 'Valider',
      },
      errors: { requiredField: 'Ce champ est requis' },
      confirmations: {
        defective: {
          title: 'Votre casque est concerné.',
          p1: "Nous vous demandons de ne plus l'utiliser et de vous rapprocher du magasin Decathlon de votre choix, avec votre casque, afin de proceder au remboursement ou au remplacement de celui-ci.",
          p2: "Pour plus d'information, nous vous conseillons de vous rapprochez de votre centre de relation clients qui se trouve sur le mail que vous avez reçu.",
        },
        good: {
          title: "Votre casque n'est pas concerné.",
          p1: "Nous vous confirmons que votre casque n'est pas concerné par ce rappel, vous pouvez donc continuer à utiliser celui-ci en toute sécurité.",
          p2: 'Nous nous excusons pour la gêne occasionnée.',
          p3: 'Sportivement,',
          p4: 'Les équipes Décathlon',
        },
        resetForm: "J'ai un autre casque à vérifier",
        exit: 'Quitter',
        findShop: 'Trouver mon magasin',
      },
    },
    findTheSerialNumber: {
      title: 'Où trouver mon numéro de lot ?',
      subTitle:
        "Le numéro de lot se trouve à l'interieur du casque sous la mousse située à l'avant du casque.",
    },
    description2:
      'Seuls certains numéros de lot, de certaines couleurs et tailles, sont concernés. Nous vous invitons à sélectionner le n° de lot correspondant à celui indiqué sur votre casque afin de savoir si votre casque est concerné par cette campagne de rappel :',
  },
  meta: { localeName: 'Français' },
}

export default fr
