import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import {
  Button,
  Card,
  Columns,
  Element,
  Image,
  Modal,
  Form as BulmaForm,
  Icon,
} from 'react-bulma-components'
import InputField from '../form/fields/input'
import SelectField from '../form/fields/select'
import { countriesAsSelectioOptions } from '../../constants/countries'
// @ts-expect-error
import helmetBatchImg from '../../assets/images/helmet-batch.png'
import { Translate, translate } from '../../utils/translations'
import { useTranslations } from '../../hooks/use-translations'
import { BiSearch } from 'react-icons/bi'
import { checkStatusDataCreationPayload } from '../../../../backend/src/services/product-items/product-items.schema'

export interface CheckStatusFormProps {
  onSubmit(values: checkStatusDataCreationPayload): void
}

const initialValues = {
  requestor: { firstName: '', lastName: '', email: '', phone: '', country: '' },
  batchNumber: '',
}

if (process.env.NODE_ENV === 'development' && false) {
  initialValues.requestor.firstName = 'Jean'
  initialValues.requestor.lastName = 'Dupuis'
  initialValues.requestor.email = 'j.dupuis@example.com'
  initialValues.requestor.phone = '0123456789'
  initialValues.requestor.country = 'BE'
  initialValues.batchNumber = '010358378766260121000000'
  initialValues.batchNumber = 'KT24040045-10'
}

const CheckStatusForm: React.FC<CheckStatusFormProps> = ({ onSubmit }) => {
  const [showModal, setShowModal] = useState(false)

  const ERRORS = {
    REQUIRED_FIELD: translate('home.form.errors.requiredField'),
    UNKNOWN_SERIAL_NUMBER: translate('home.form.errors.unknownSerialNumber'),
    PROVIDE_6_NUMBERS: translate('home.form.errors.provide6Numbers'),
  }

  const { current: currentLocale } = useTranslations()

  const batchsList = [
    { value: 'KT24040006-15', label: 'KT24040006-15' },
    { value: 'KT24040006-16', label: 'KT24040006-16' },
    { value: 'KT24040018-14', label: 'KT24040018-14' },
    { value: 'KT24040018-16', label: 'KT24040018-16' },
    { value: 'KT24040034-2', label: 'KT24040034-2' },
    { value: 'KT24040034-4', label: 'KT24040034-4' },
    { value: 'KT24040034-8', label: 'KT24040034-8' },
    { value: 'KT24040034-10', label: 'KT24040034-10' },
    { value: 'KT24040045-8', label: 'KT24040045-8' },
    { value: 'KT24040045-9', label: 'KT24040045-9' },
    { value: 'KT24040045-10', label: 'KT24040045-10' },
    { value: 'nc', label: translate('home.form.fields.nc') },
  ]

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: any = {}
        const requestor = values.requestor || {}
        if (!requestor.firstName) errors['requestor.firstName'] = ERRORS.REQUIRED_FIELD
        if (!requestor.lastName) errors['requestor.lastName'] = ERRORS.REQUIRED_FIELD
        if (!requestor.email || !requestor.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,12}$/))
          errors['requestor.email'] = ERRORS.REQUIRED_FIELD
        if (!requestor.country) errors['requestor.country'] = ERRORS.REQUIRED_FIELD
        if (!values.batchNumber) errors.batchNumber = ERRORS.REQUIRED_FIELD
        //TODO: serial number family
        return errors
      }}
      onSubmit={values => {
        setIsSubmitting(true)
        onSubmit({
          ...values,
          requestor: {
            ...values.requestor,
            language: currentLocale!,
          },
        })
      }}
    >
      {props => (
        <Form noValidate>
          {/*<FormikObserver values={props.values} onChange={values => onFormChange(values)} /> */}
          <Columns centered>
            <Columns.Column tablet={{ size: 12 }} desktop={{ size: 12 }} widescreen={{ size: 8 }}>
              <Card>
                <Card.Content>
                  <Field
                    name="requestor.firstName"
                    label={translate('home.form.fields.firstName')}
                    component={InputField}
                  />
                  <Field
                    name="requestor.lastName"
                    label={translate('home.form.fields.lastName')}
                    component={InputField}
                  />

                  <Field
                    name="requestor.email"
                    label={translate('home.form.fields.email')}
                    component={InputField}
                    type="email"
                  />
                  <Field
                    name="requestor.phone"
                    label={translate('home.form.fields.phone')}
                    type="tel"
                    component={InputField}
                  />
                  <Field
                    name="requestor.country"
                    label={translate('home.form.fields.country')}
                    type="tel"
                    component={SelectField}
                    options={countriesAsSelectioOptions}
                    selectDefaultChooseOptionLabel={translate('home.form.fields.choose')}
                  />

                  <Field
                    name="batchNumber"
                    label={
                      <>
                        {translate('home.form.fields.serialNumber')}
                        <Element mb={2} textWeight="normal">
                          <Translate value="home.findTheSerialNumber.subTitle" />
                        </Element>
                        <Image src={helmetBatchImg} style={{ maxWidth: 600, margin: '0' }} />
                      </>
                    }
                    type="tel"
                    component={SelectField}
                    options={batchsList}
                    selectDefaultChooseOptionLabel={translate('home.form.fields.choose')}
                  />

                  <Button
                    fullwidth
                    color="primary"
                    style={{ marginTop: 'auto' }}
                    textWeight="bold"
                    mt={4}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <Translate value="home.form.fields.submit" />
                  </Button>
                </Card.Content>
              </Card>
            </Columns.Column>
          </Columns>
          <FindSerialNumberModal show={showModal} onClose={() => setShowModal(false)} />
        </Form>
      )}
    </Formik>
  )
}

export default CheckStatusForm

const FindSerialNumberModal: React.FC<{ show: boolean; onClose: () => void }> = ({
  show,
  onClose,
}) => {
  return (
    <Modal closeOnBlur closeOnEsc showClose show={show} onClose={onClose}>
      <Modal.Card>
        <Modal.Card.Header backgroundColor="white" style={{ borderBottom: 'none' }}>
          <Modal.Card.Title mobile={{ textSize: 6 }}>
            <Translate value="home.findTheSerialNumber.title" />
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body style={{ paddingTop: 0 }}>
          <Element mb={2}>
            <Translate value="home.findTheSerialNumber.subTitle" />
          </Element>
          <Image src={helmetBatchImg} style={{ maxWidth: 800, margin: '0 auto' }} />
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  )
}
