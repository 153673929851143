// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const sk: RequiredTranslationsTemplate = {
  meta: { localeName: 'Slovenčina' },
  home: {
    title: 'STIAHNUTIE PRODUKTU Z PREDAJA Prilba na horskú cyklistiku EXPL500 a ST500',
    description:
      'Ďakujeme, že ste si u nás zakúpili prilbu na horskú cyklistiku EXPL500 alebo ST500. V jednom z plastových komponentov tejto prilby sme našli vadu. Diel sa môže zlomiť a prilba už nebude bezpečne sedieť na hlave.',
    form: {
      title: 'Vyplňte, prosím, nasledujúce informácie',
      fields: {
        serialNumber: 'Číslo šarže',
        firstName: 'Meno:',
        lastName: 'Priezvisko:',
        email: 'E-mail:',
        phone: 'Tel. č.:',
        country: 'Krajina:',
        submit: 'Potvrdiť',
        choose: 'Vybrať',
        nc: 'INÉ (číslo šarže mojej prilby nie je na tomto zozname)',
      },
      errors: { requiredField: 'Toto pole je povinné' },
      confirmations: {
        good: {
          title: 'Stiahnutie z predaja sa vašej prilby netýka.',
          p1: 'Potvrdzujeme, že toto stiahnutie z predaja sa vašej prilby netýka. Tento produkt môžete naďalej bezpečne používať.',
          p2: 'Ospravedlňujeme sa za vzniknuté nepríjemnosti.',
          p3: 'S pozdravom,',
          p4: 'Tím Decathlon',
        },
        defective: {
          title: 'Stiahnutie z predaja sa týka vašej prilby',
          p1: 'Žiadame vás, aby ste prilbu ihneď prestali používať a priniesli ju do najbližšej predajne Decathlon, kde vám za produkt vrátime peniaze alebo ho vymeníme za iný.',
          p2: 'Pre viac informácií kontaktujte Zákaznícke centrum. Kontaktné informácie nájdete v e-maile, ktorý ste dostali.',
          p3: 'Nájdite najbližšiu predajňu Decathlon',
          decathlonLocatorLink: 'https://www.decathlon.sk/content/24-nase-predajne-decathlon',
          p4: 'Nižšie nájdete dokument „Oznámenie o vrátení produktu“, ktorý si, prosím, prineste so sebou:',
          p5: 'Pre viac informácii kontaktujte naše zákaznícke centrum, ktoré je Vám k dispozícii na telefónnom čísle +421 2 221 16 888 a to od pondelka do piatka (od 9h do 18h) a tiež cez víkend (od 9h do 17h).',
        },
        resetForm: 'Skontrolovať ďalšiu prilbu',
        exit: 'Odísť',
        findShop: 'Nájsť predajňu',
      },
    },
    findTheSerialNumber: {
      title: 'Kde nájdem číslo šarže?',
      subTitle: 'Číslo šarže sa nachádza vo vnútri prilby, pod penovou výstelkou v prednej časti.',
    },
    description2:
      'Toto stiahnutie z predaja sa týka len určitých šarží, farieb a veľkostí. Vyberte, prosím, číslo šarže, ktoré zodpovedá číslu na vašej prilbe a tak zistite, či sa toto stiahnutie z predaja týka aj vašej prilby.',
  },
}

export default sk
