import { Application, feathers } from '@feathersjs/feathers'
import type { TransportConnection, Params } from '@feathersjs/feathers'
import authenticationClient from '@feathersjs/authentication-client'
/* import type { GoogleSheet, GoogleSheetData, GoogleSheetQuery } from './services/google-sheets/google-sheets'
export type { GoogleSheet, GoogleSheetData, GoogleSheetQuery } */

import type {
  ProductItems,
  ProductItemsData,
  ProductItemsQuery,
  ProductItemsService
} from './services/product-items/product-items'
export type { ProductItems, ProductItemsData, ProductItemsQuery }

/* import type { User, UserData, UserQuery } from './services/users/users'
export type { User, UserData, UserQuery } */

import type { AuthenticationClientOptions } from '@feathersjs/authentication-client'

/* const userServiceMethods = ['find', 'get', 'create', 'update', 'patch', 'remove'] as const
type UserClientService = Pick<UserService<Params<UserQuery>>, typeof userServiceMethods[number]>
 */
const productItemsServiceMethods = ['find', 'checkStatus', 'getSerialNumbersFamilies'] as const
type ProductItemsClientService = Pick<
  ProductItemsService<Params<ProductItemsQuery>>,
  typeof productItemsServiceMethods[number]
>

export interface ServiceTypes {
  'product-items': ProductItemsClientService
  /* authentication: Pick<AuthenticationService, 'create' | 'remove'>
  users: UserClientService */
  //
}

/**
 * Returns a typed client for the fandi-sav app.
 *
 * @param connection The REST or Socket.io Feathers client connection
 * @param authenticationOptions Additional settings for the authentication client
 * @see https://dove.feathersjs.com/api/client.html
 * @returns The Feathers client application
 */
export const createClient = <Configuration = any>(
  connection: TransportConnection<ServiceTypes>,
  authenticationOptions: Partial<AuthenticationClientOptions> = {}
): Application<ServiceTypes, Configuration> => {
  const client = feathers<ServiceTypes, Configuration>()

  client.configure(connection)
  client.configure(authenticationClient(authenticationOptions))

  /* client.use('users', connection.service('users'), {
    methods: userServiceMethods
  }) */
  client.use('product-items', connection.service('product-items'), {
    methods: productItemsServiceMethods
  })
  /* client.use('google-sheets', connection.service('google-sheets'), {
    methods: googleSheetServiceMethods
  }) */
  return client
}
