function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import PropTypes from 'prop-types';
import BaseComponent from './Base';

class I18n extends BaseComponent {
  constructor() {
    super(...arguments);

    _defineProperty(this, "render", () => this.props.render());
  }

}

I18n.propTypes = {
  render: PropTypes.func.isRequired
};
export default I18n;