import { countries as countriesList } from 'countries-list'

const countries = Object.keys(countriesList).map(code => {
  const country = countriesList[code]
  return {
    name: country.languages[1] ? country.name : countriesList[code].native,
    code,
  }
})

export const countriesAsSelectioOptions = countries.map(country => ({
  label: country.name,
  value: country.code,
}))

export default countries
