// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const de: RequiredTranslationsTemplate = {
  meta: { localeName: 'Deutsch (Switzerland)' },
  home: {
    title: 'PRODUKTRÜCKRUF MTB Fahrradhelm Expl 500 und ST 500 von Rockrider',
    description:
      'Du hast einen Expl 500 oder ST 500 Mountainbike-Helm von Rockrider gekauft und wir danken dir für dein Vertrauen. Leider haben wir einen Defekt an einem der Kunststoffteile festgestellt, aus denen der Helm besteht. Dieser Defekt könnte dazu führen, dass der Helm nicht mehr sicher auf dem Kopf sitzt. ',
    description2:
      'Nur bestimmte Chargennummern, Farben und Größen sind davon betroffen. Bitte wähle die Chargennummer, die auf deinem Helm angegeben ist, um herauszufinden, ob dein Produkt von diesem Rückruf betroffen ist:',
    form: {
      title: 'Bitte gib die folgenden Informationen ein:',
      fields: {
        firstName: 'Vorname',
        lastName: 'Name',
        email: 'E-Mail',
        phone: 'Tel.',
        country: 'Land',
        serialNumber: 'Chargennummer',
        choose: 'Auswählen',
        nc: 'ANDERE (meine Chargennummer ist nicht in dieser Liste enthalten).',
        submit: 'Bestätigen',
      },
      errors: { requiredField: 'Dieses Feld muss ausgefüllt werden.' },
      confirmations: {
        defective: {
          title: 'Dein Helm ist betroffen.',
          p1: 'Wir bitten dich, das Produkt nicht mehr zu benutzen und mit deinem Helm in eine DECATHLON Filiale deiner Wahl zu gehen, um ihn erstatten oder ersetzen zu lassen.',
          p2: 'Für weitere Informationen empfehlen wir dir, dich an unseren Kundenservice zu wenden, den du in der E-Mail findest, die du erhalten hast.',
        },
        good: {
          title: 'Dein Helm ist nicht betroffen.',
          p1: 'Wir bestätigen dir, dass dein Helm nicht von diesem Rückruf betroffen ist, sodass du ihn weiterhin sicher verwenden kannst.',
          p2: 'Wir entschuldigen uns für die entstandenen Unannehmlichkeiten.',
          p3: 'Mit sportlichen Grüßen,',
          p4: 'Dein DECATHLON Team',
        },
        resetForm: 'Ich möchte einen anderen Helm überprüfen',
        exit: 'Verlassen',
        findShop: 'Meine Filiale finden',
      },
    },
    findTheSerialNumber: {
      title: 'Wo finde ich meine Chargennummer?',
      subTitle:
        'Die Chargennummer befindet sich im Inneren des Helms unter dem Schaumstoff im vorderen Bereich des Helms.',
    },
  },
}

export default de
