// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const lva: RequiredTranslationsTemplate = {
  meta: { localeName: 'Latviski' },
  home: {
    title: 'Produkta atsaukšana ROCKRIDER ķivere MTB EXPL 500 vai ST500',
    description:
      'Paldies, ka iegādājāties kalnu velosipēda ķiveri EXPL500 vai ST500. Mēs esam konstatējuši defektu vienā no plastmasas detaļām, kas var salūzt un apdraudēt ķiveres spēju droši noturēties uz jūsu galvas.',
    form: {
      title: 'Lūdzu, ievadiet tālāk norādīto informāciju',
      fields: {
        serialNumber: 'Partijas numurs',
        firstName: 'Vārds:',
        lastName: 'Uzvārds:',
        email: 'E-pasta adrese:',
        phone: 'Tālruņa numurs:',
        country: 'Valsts:',
        submit: 'Iesniegt',
        choose: 'Izvēlieties',
        nc: 'CITA (mana partijas numura nav šajā sarakstā)',
      },
      errors: { requiredField: 'Šis lauks ir obligāts' },
      confirmations: {
        good: {
          title: 'Jūsu ķivere netiek ietekmēta.',
          p1: 'Mēs apstiprinām, ka šis atsaukums neietekmē jūsu ķiveri, tāpēc varat turpināt droši lietot savu produktu.',
          p2: 'Atvainojamies par sagādātajām neērtībām.',
          p3: 'Ar cieņu',
          p4: 'Decathlon komanda',
        },
        defective: {
          title: 'Jūsu ķivere ir ietekmēta.',
          p1: 'Lūdzam nekavējoties pārtraukt šī izstrādājuma lietošanu un nogādāt ķiveri tuvākajā Decathlon veikalā, lai varētu veikt naudas atmaksu vai produkta nomaiņu.',
          p2: 'Lai iegūtu plašāku informāciju, iesakām sazināties ar klientu apkalpošanas centru. Kontaktinformācija ir atrodama saņemtajā e-pastā.',
          p3: 'Pentru a găsi cel mai apropiat Atelier Decathlon:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'Veți găsi mai jos „Notificarea de retur client” pentru a o prezenta Tehnicianului nostru când returnați bicicleta la atelier:',
          p5: 'Pentru mai multe informații, vă rugăm să ne contactați la numarul de telefon 021 408 5005 de Luni-Vineri 09h00 la 20h00 și Sâmbăta-Duminica 10h00 la 19h00',
        },
        resetForm: 'Man ir jāpārbauda vēl viena ķivere.',
        exit: 'Iziet',
        findShop: 'Atrodi manu veikalu',
      },
    },
    findTheSerialNumber: {
      title: 'Kur es varu atrast savu partijas numuru?',
      subTitle: 'Partijas numurs atrodas ķiveres iekšpusē, zem polsterējuma priekšpusē.',
    },
    description2:
      'Tiek ietekmēti tikai noteikti partijas numuri, krāsas un izmēri. Mēs aicinām jūs pārbaudīt partijas numuru uz ķiveres, lai noskaidrotu, vai šī atsaukšanas kampaņa ir ietekmējusi jūsu produktu.',
  },
}

export default lva
