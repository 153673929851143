import { HookManager } from './base.js';
import { functionHooks, hookDecorator, objectHooks } from './hooks.js';
export * from './hooks.js';
export * from './compose.js';
export * from './base.js';
export * from './regular.js';
/**
 * Initializes a hook settings object with the given middleware.
 * @param mw The list of middleware
 * @param options Middleware options (params, default, props)
 */
export function middleware(mw, options) {
    const manager = new HookManager().middleware(mw);
    if (options) {
        if (options.params) {
            manager.params(...options.params);
        }
        if (options.defaults) {
            manager.defaults(options.defaults);
        }
        if (options.props) {
            manager.props(options.props);
        }
    }
    return manager;
}
// Fallthrough to actual implementation
export function hooks(...args) {
    const [target, _hooks] = args;
    if (typeof target === 'function' &&
        (_hooks instanceof HookManager || Array.isArray(_hooks) ||
            args.length === 1)) {
        return functionHooks(target, _hooks);
    }
    if (args.length === 2) {
        return objectHooks(target, _hooks);
    }
    return hookDecorator(target);
}
