// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const pt: RequiredTranslationsTemplate = {
  meta: { localeName: 'Português' },
  home: {
    title: 'RECOLHA DE PRODUTO Capacete de BTT EXPL500 e ST500',
    description:
      'Adquiriu um capacete de BTT EXPL500 ou ST500 e agradecemos-lhe por isso.\nDetetámos um defeito numa das peças plásticas que o compõem. Isto evita que o capacete\ncumpra plenamente a sua função protetora.',
    form: {
      title: 'Por favor, indica a informação seguinte',
      fields: {
        serialNumber: 'N.° do lote',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Nome Próprio',
        lastName: 'Apelidos',
        email: 'Direção de correio electrónico',
        phone: 'Tel.',
        country: 'País',
        submit: 'Validar',
        choose: 'Seleccionar',
        nc: 'OUTRO (o meu número de lote não está nesta lista)',
      },
      errors: {
        requiredField: 'Este campo é obrigatório',
        unknownSerialNumber: 'Número de série desconhecido',
        provide6Numbers: 'Por favor note que são necessários 6 dígitos',
      },
      confirmations: {
        good: {
          title: 'O seu capacete não está abrangido',
          p1: '\nConfirmamos que o seu capacete não está abrangido por este aviso de produto, pode continuar a utilizá-lo com toda a segurança.',
          p2: 'Lamentamos o transtorno que lhe possamos ter causado',
          p3: 'Desportivamente',
          p4: 'A equipa Decathlon',
        },
        defective: {
          title: 'O seu capacete está abrangido',
          p1: 'Pedimos que deixe de usar o capacete e se dirija à loja Decathlon da sua preferência, com o seu capacete, para que possamos reembolsá-lo ou substituí-lo.',
          p2: 'Para mais informações aconselhamos que contacte o centro de atendimento ao cliente, cujos dados se\nencontram no e-mail que recebeu',
          p3: 'Acesse este link para verificar todas as nossas lojas espalhas, e encontrar a mais próxima a você:',
          decathlonLocatorLink: 'https://www.decathlon.com.br/lojas/',
          p4: 'Clique no icone abaixo para baixar o documento “Notificação de Retorno do Cliente”, este que você deve apresentar na loja de sua escolha.',
          p5: 'Para mais informações, entre em contato com nossa equipe de atendimento pelo telefone (11) 3003-1414 (Atendimento de segunda à sábado e feriado) ou por e-mail no endereço https://www.decathlon.com.br/atendimento',
        },
        'reset-form': 'Tenho outro capacete para verificar',
        exit: 'Sair',
        'find-shop': 'Encontrar a minha loja',
        resetForm: 'Tenho outro capacete para verificar',
        findShop: 'Encontrar a minha loja',
      },
    },
    findTheSerialNumber: {
      title: 'Onde posso encontrar o meu número do lote?',
      description:
        'O número composto por 30 digitos está impresso na parte inferior do quadro de sua bicicleta, próximo ao movimento central. Veja a imagem abaixo: ',
      subTitle: 'O número do lote encontra-se no interior do capacete',
    },
    description2:
      'Apenas alguns números de lote estão afetados, por isso, pedimos que indique os últimos números do lote para saber se o seu capacete está abrangido por esta recolha',
  },
}

export default pt
