import React from 'react'
import { Element, Form } from 'react-bulma-components'

export interface FormFieldProps {
  children: React.ReactNode
  label?: string
  error?: string
  help?: string
}

const FormField: React.FunctionComponent<FormFieldProps> = ({ help, label, children, error }) => {
  return (
    <Form.Field>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control>{children}</Form.Control>
      {help && <Form.Help>{help}</Form.Help>}
      {error && (
        <Element textColor="danger" mt={1}>
          {error}
        </Element>
      )}
    </Form.Field>
  )
}

export default FormField
