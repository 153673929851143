// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const hu: RequiredTranslationsTemplate = {
  meta: { localeName: 'Magyar' },
  home: {
    title:
      'Termék visszahívás EXPL500 és ST500 MTB kerékpáros sisak                                                                                                                                                                                                                ',
    description:
      'Köszönjük, hogy megvásárolta az EXPL500 vagy ST500 kerékpáros sisakot. Hibát észleltünk az egyik műanyag alkatrészben, amely eltörhet és veszélyeztetheti a sisak azon képességét, hogy biztonságosan a fején maradjon.',
    form: {
      title: 'Kérjük, adja meg a következő információkat',
      fields: {
        serialNumber: 'Nyomonkövetési szám',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Keresztnév:',
        lastName: 'Vezetéknév:',
        email: 'E-mail cím:',
        phone: 'Telefonszám:',
        country: 'Ország:',
        submit: 'Elküldés',
        choose: 'Válasszon',
        nc: 'Egyéb (a nyomonkövetési szám nincs a listában)',
      },
      errors: {
        requiredField: 'Ez a mező kötelező',
        unknownSerialNumber: 'Nepoznat serijski broj',
        provide6Numbers: 'Pažnja, potrebno je 6 znamenki',
      },
      confirmations: {
        good: {
          title: 'A sisakja nem érintett.',
          p1: 'Megerősítjük, hogy a sisakja nem érintett a visszahívásban, így továbbra is biztonságosan használhatja a terméket.',
          p2: 'Elnézést kérünk az okozott kellemetlenségekért.',
          p3: 'Üdvözlettel,',
          p4: 'A Decathlon csapata',
        },
        defective: {
          title: 'A sisakja érintett.',
          p1: 'Kérjük, hogy azonnal hagyja abba a termék használatát, és vigye a sisakot a legközelebbi Decathlon áruházba, hogy visszatérítsük vagy kicseréljük a terméket.',
          p2: 'További információkért javasoljuk, hogy forduljon a központi ügyfélszolgálathoz. Az elérhetőségeket a kapott e-mailben találja.',
          p3: 'Ovdje možete pronaći najbliži Decathlon servis:',
          decathlonLocatorLink: 'https://www.decathlon.hr/content/35-trgovine',
          p4: 'U nastavku se nalazi “Obavijest o povratu za kupca” koju je potrebno predočiti serviseru prilikom zaprimanja u servis:',
          p5: 'Za više informacija molimo Vas da kontaktirate naš specijalizirani pozivni centar za ovaj opoziv na 0 805 38 38 49, besplatno iz Francuske (od ponedjeljka do petka od 9:00 do 19:00), ili putem e-maila na rappel.decathlon@fandi.fr.',
        },
        'reset-form': 'Želim provjeriti još jednu kacigu',
        exit: 'Kilépes',
        'find-shop': 'Pronađi moju trgovinu',
        resetForm: 'Van még egy sisakom, amit ellenőrizni kell.',
        findShop: 'Találd meg az áruházam',
      },
    },
    findTheSerialNumber: {
      title: 'Hol találhatom meg a nyomonkövetési számot:',
      description:
        '30-znamenkasti broj otisnut je na donjem dijelu okvira bicikla, ispod srednjeg pogona i poluga pedala. Pogledajte sliku ispod.',
      subTitle: 'A nyomonkövetési szám a sisak belsejében az elülső szivacs alatt található.',
    },
    description2:
      'Csak bizonyos tételszámok, színek és méretek érintettek. Kérjük, ellenőrizze a sisakján található tételszámot, hogy megtudja, az Ön termékét érinti-e ez a visszahívás.',
  },
}

export default hu
