import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './containers/app'
import { queryClient } from './queries'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { setTranslations, setLocale } from 'react-i18nify'
import locales from './locales'

// Configure i18n (default language is english)
setTranslations(locales)
setLocale('en') // TEMP for test => real default is 'en' (check brower langue detector too)

// React app
const container = document.getElementById('app')
const root = createRoot(container!)
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ReactQueryDevtools />
  </QueryClientProvider>,
)
