import rest from '@feathersjs/rest-client'
import axios from 'axios'
import { createClient } from '../../../backend/src/client'

// Connect to a different URL
const restClient = rest(process.env.API_HOSTNAME || 'http://localhost:3030').axios(axios)

const app = createClient(restClient)

export default app
