// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const hr: RequiredTranslationsTemplate = {
  meta: { localeName: 'Hrvatski' },
  home: {
    title: 'POVLAČENJE PROIZVODA kaciga za brdski biciklizam EXPL500 i ST500',
    description:
      'Kupili ste kacigu za brdski bicikl EXPL500 ili ST500 i mi vam zahvaljujemo. Identificirali smo kvar na jednom od plastičnih dijelova koji je čine. Može se slomiti i u ovom slučaju ne može osigurati držanje kacige na glavi.\r',
    form: {
      title: 'Molimo, unesite sljedeće podatke:',
      fields: {
        serialNumber: 'Broj serije',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Ime',
        lastName: 'Prezime',
        email: 'e-mail',
        phone: 'Broj telefona',
        country: 'Država',
        submit: 'Potvrdite',
        choose: 'Odaberite',
        nc: 'OSTALO (broj moje serije nije na ovom popisu)',
      },
      errors: {
        requiredField: 'Ovo polje je obavezno',
        unknownSerialNumber: 'Nepoznat serijski broj',
        provide6Numbers: 'Pažnja, potrebno je 6 znamenki',
      },
      confirmations: {
        good: {
          title: 'Vaša kaciga se ne povlači.',
          p1: 'Ovo se povlačenje ne odnosi na vašu kacigu, tako da je možete nastaviti koristiti potpuno sigurno.',
          p2: 'Ispričavamo se zbog nastalih neugodnosti.',
          p3: 'Sportski pozdrav!',
          p4: 'Decathlonov tim',
        },
        defective: {
          title: 'Vaša kaciga se povlači.',
          p1: 'Molimo vas da je više ne koristite i da se obratite Decathlonovoj trgovini po svom izboru sa svojom kacigom kako biste je zamijenili ili vratili uz povrat novca.',
          p2: 'Za više informacija, savjetujemo da kontaktirate našu korisničku službu, na kontakt koji se nalazi u e-mailu koji ste primili.',
          p3: 'Ovdje možete pronaći najbliži Decathlon servis:',
          decathlonLocatorLink: 'https://www.decathlon.hr/content/35-trgovine',
          p4: 'U nastavku se nalazi “Obavijest o povratu za kupca” koju je potrebno predočiti serviseru prilikom zaprimanja u servis:',
          p5: 'Za više informacija molimo Vas da kontaktirate naš specijalizirani pozivni centar za ovaj opoziv na 0 805 38 38 49, besplatno iz Francuske (od ponedjeljka do petka od 9:00 do 19:00), ili putem e-maila na rappel.decathlon@fandi.fr.',
        },
        'reset-form': 'Želim provjeriti još jednu kacigu',
        exit: 'Izađi',
        'find-shop': 'Pronađi moju trgovinu',
        resetForm: 'Želim provjeriti još jednu kacigu',
        findShop: 'Pronađi moju trgovinu',
      },
    },
    findTheSerialNumber: {
      title: 'Gdje mogu pronaći svoj broj serije?',
      description:
        '30-znamenkasti broj otisnut je na donjem dijelu okvira bicikla, ispod srednjeg pogona i poluga pedala. Pogledajte sliku ispod.',
      subTitle:
        'Broj serije nalazi se unutar kacige, ispod pjene koja se nalazi na prednjoj strani kacige.',
    },
    description2:
      'Zahvaćeni su samo određeni brojevi serija, određenih boja i veličina. Pozivamo vas da odaberete broj serije koji odgovara onom naznačenom na vašoj kacigi kako biste saznali utječe li ova kampanja povlačenja na vašu kacigu:',
  },
}

export default hr
