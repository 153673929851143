import { useMemo } from 'react'
import { setLocale } from 'react-i18nify'
import { useParams } from 'react-router-dom'
import locales from '../locales'

interface UseTranslationsReturnType {
  current: string | undefined
  all: {
    [key: string]: string
  }
}

export const useTranslations = (): UseTranslationsReturnType => {
  const { lang } = useParams()
  const allLocales = useMemo(
    () =>
      Object.keys(locales).reduce((acc, current) => {
        acc[current] = locales[current].meta.localeName
        return acc
      }, {}),
    [locales],
  )
  setLocale(lang && locales[lang] ? lang : 'en')
  /* useEffect(() => {
    setLocale(lang && locales[lang] ? lang : 'en')
    console.log(getLocale())
  }, [lang, locales]) */

  return { current: lang, all: allLocales }
}
