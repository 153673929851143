// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const it: RequiredTranslationsTemplate = {
  meta: { localeName: 'Italiano (Switzerland)' },
  home: {
    title: 'RICHIAMO PRODOTTO: Casco Mountainbike EXPL500 e ST500',
    description:
      'Avete acquistato un casco da mountain bike EXPL500 o ST500 e ti ringraziamo. Abbiamo individuato un difetto in una delle parti in plastica che lo compongono. Questa può rompersi e di conseguenza il casco potrebbe non rimanere sulla testa.\n',
    description2:
      'Sono interessati solo determinati numeri di lotto, di determinati colori e dimensioni. Ti invitiamo ad indicare di seguito il numero di lotto corrispondente a quello indicato sul tuo casco per scoprire se è interessato da questa campagna di richiamo',
    form: {
      title: 'Grazie di inserire le seguenti informazioni',
      fields: {
        firstName: 'Cognome',
        lastName: 'Nome',
        email: 'Indirizzo email',
        phone: 'Numero di telefono',
        country: 'Paese',
        serialNumber: 'N° di lotto',
        choose: 'Selezionare',
        nc: 'Altro (il mio numero di lotto non è presente in questa lista)',
        submit: 'Validare',
      },
      errors: { requiredField: 'Questo campo è obbligatorio' },
      confirmations: {
        defective: {
          title: 'Il vostro casco è interessato dal Richiamo',
          p1: 'Vi chiediamo di non utilizzarlo più e di recarvi nel negozio Decathlon a voi più vicino con il vostro casco al fine di procedere ad un rimborso o ad una sostituzione.',
          p2: 'Per maggiori informazioni vi invitiamo a rivolgervi al Servizio Clienti tramite i contatti presenti nella mail che avete ricevuto',
        },
        good: {
          title: 'Il vostro casco NON è interessato dal Richiamo',
          p1: 'Vi confermiamo che il vostro casco non è interessato da questa campagna di Richiamo. Potete quindi continuare ad utilizzarlo.',
          p2: "Ci scusiamo per l'inconveniente causato.",
          p3: 'Sportivamente',
          p4: 'La squadra Decathlon',
        },
        resetForm: 'Ho un altro casco da verificare',
        exit: 'Chiudere',
        findShop: 'Ricerca negozio',
      },
    },
    findTheSerialNumber: {
      title: 'Dove trovare il numero di lotto del casco?',
      subTitle:
        'Il numero di lotto si trova nella parte interna del casco, sotto la mousse situata nella parte anteriore del casco.',
    },
  },
}

export default it
